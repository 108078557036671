// Function to add 'active' class to the navigation link when scrolled into view
function setActiveNavLink() {
    // Get all navigation links
    const navLinks = document.querySelectorAll('.navbar a');

    // Add event listener to each link
    navLinks.forEach(link => {
        link.addEventListener('click', function() {
            // Remove 'active' class from all links
            navLinks.forEach(link => link.classList.remove('active'));

            // Add 'active' class to the clicked link
            this.classList.add('active');
        });
    });

    // Add scroll event listener to check which section is in view
    window.addEventListener('scroll', function() {
        // Get the current scroll position
        const scrollPosition = window.scrollY;

        // Check each section to see if it's in view
        document.querySelectorAll('section').forEach(section => {
            const top = section.offsetTop;
            const height = section.offsetHeight;
            const sectionId = section.getAttribute('id');

            if (scrollPosition >= top && scrollPosition < top + height) {
                // Remove 'active' class from all navigation links
                navLinks.forEach(link => link.classList.remove('active'));

                // Add 'active' class to the corresponding navigation link
                document.querySelector(`.navbar a[href="#${sectionId}"]`).classList.add('active');
            }
        });
    });
}
document.addEventListener("DOMContentLoaded", function() {
    const observer = new IntersectionObserver(entries => {
        entries.forEach(entry => {
            const element = entry.target.querySelector('.noanimfornow');

            if (entry.isIntersecting) {
                element.classList.add('animateme');
                return; // if we added the class, exit the function
            }

            // We're not intersecting, so remove the class!
            element.classList.remove('animateme');
        });
    });

    observer.observe(document.querySelector('.animwrapper'));
});

const video = document.getElementById('background-video');
const fallbackImage = document.getElementById('fallback-image');

    setTimeout(() => {
        if (video.readyState < 2 || video.paused) {
            video.style.display = 'none';
            fallbackImage.style.display = 'block';
        }
    }, 500); // 3-second delay to check if video plays
// Run the function on page load
window.onload = setActiveNavLink;


document.querySelector('.hamburger').addEventListener('click', function() {
    document.querySelector('.navbar').classList.toggle('active');
});


// Get the image element
let mybutton = document.getElementById("backToTopBtn");

// When the user scrolls down 20px from the top of the document, show the button
window.onscroll = function() {scrollFunction()};

function scrollFunction() {
  if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
    mybutton.style.display = "block";
  } else {
    mybutton.style.display = "none";
  }
}

// When the user clicks on the button, scroll to the top of the document
function topFunction() {
    window.scrollTo({
        top: 0,
        behavior: 'smooth'
    });
}

// Add event listener for image click
mybutton.addEventListener("click", topFunction);


document.addEventListener('DOMContentLoaded', () => {
    document.querySelectorAll('.toggle-button').forEach(button => {
      button.addEventListener('click', () => {
        const agendaStack = button.closest('.session-type').nextElementSibling;
        const isHidden = agendaStack.classList.toggle('hidden');
  
        button.textContent = isHidden ? 'Show More' : 'Show Less';
      });
    });
});

document.getElementById("toggle-button").addEventListener("click", function () {
    let hiddenItems = document.querySelectorAll(".hidden-item");
    if (this.innerText === "Expand to see more items") {
        hiddenItems.forEach(function (item) {
            item.style.display = "block";
        });
        this.innerText = "Shrink to see fewer items";
    } else {
        hiddenItems.forEach(function (item) {
            item.style.display = "none";
        });
        this.innerText = "Expand to see more items";
    }
});

var countDownDate = new Date("Oct 12, 2024 09:00:00").getTime();

var countdownfunction = setInterval(function() {

  var now = new Date().getTime();

  var distance = countDownDate - now;

  var days = Math.floor(distance / (1000 * 60 * 60 * 24));

  document.getElementById("countdown").innerHTML = "Just " + days + " days to the big day!";

  if (distance < 0) {
    clearInterval(countdownfunction);
    document.getElementById("countdown").innerHTML = "The event has started!";
  }
}, 1000);